import React from "react";

function Info(): React.JSX.Element {
	const infoTableData = [
		{
			name: "VAT Number",
			value: "IT04001560368",
		},
		{
			name: "Tax ID",
			value: "IT04001560368",
		},
		{
			name: "Share Capital",
			value: "10.000€",
		},
		{
			name: "Director",
			value: "Leonardo Pinna",
		},
		{
			name: "Company Address",
			value: "via Resistenza 3, 41014, Castelvetro di Modena (MO), Italy",
		},
	];

	const infoDocData = [
		{
			href: "/assets/documents/Statuto.pdf",
			title: "Statute (PDF) - IT",
		},
		{
			href: "/assets/documents/VisuraSoci.pdf",
			title: "Shareholders' Data (PDF) - IT",
		},
	];

	return (
		<div className="inner-div">
			<div>
				<h2>Company Legal Data</h2>
				<table>
					<tbody>
						{infoTableData.map((data) => (
							<tr>
								<th>{data.name}</th>
								<td>{data.value}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<hr />
			<div className="documents-container">
				{infoDocData.map((data) => (
					<div className="document-div">
						<a href={data.href} target="_blank" rel="noreferrer">
							<h2>{data.title}</h2>
						</a>
					</div>
				))}
			</div>
		</div>
	);
}

export default Info;
