import React from "react";
import { HomeBlock } from "./Components";

function OtherProjects(): React.JSX.Element {
	const otherData = [
		{
			href: "https://money-goals-srl.github.io/money-manager",
			text: "Money Calculator Website",
		},
		{
			href: "/money-manager-app",
			text: "Money Manager App",
		},
		{
			href: "https://academy.leonardopinna.com",
			text: "Academy di Leonardo Pinna",
		},
	];

	return (
		<div className="inner-div">
			<h2>Other Projects</h2>
			<hr />
			<div className="home-table">
				{otherData.map((data) => (
					<HomeBlock href={data.href} text={data.text} />
				))}
			</div>
		</div>
	);
}

export default OtherProjects;
