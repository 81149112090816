import React from "react";

function Highlights(): React.JSX.Element {
	const kpiData = [
		{
			name: "YouTube",
			kpis: [
				{
					name: "Subscribers",
					value: "+87.000",
				},
				{
					name: "Total Views",
					value: "+10 M",
				},
			],
		},
		{
			name: "Instagram",
			kpis: [
				{
					name: "Followers",
					value: "+4.000",
				},
			],
		},
		{
			name: "Academy",
			kpis: [
				{
					name: "Trainees",
					value: "+60",
				},
				{
					name: "Courses",
					value: "1",
				},
			],
		},
	];

	return (
		<div className="inner-div">
			<h2>KPI & Highlights</h2>
			<div className="kpi-container">
				{kpiData.map((social) => {
					return (
						<table className="kpi-table">
							<thead className="kpi-table-head">
								<td colSpan={2}>{social.name}</td>
							</thead>
							<tbody>
								{social.kpis.map((kpi) => (
									<tr>
										<td className="kpi-name">{kpi.name}</td>
										<td className="kpi-name">{kpi.value}</td>
									</tr>
								))}
							</tbody>
							{/*<div className="single-kpi-container">
            <div className="kpi-social">
              {social.name}
            </div>
            <div className="kpi-block">
              {social.kpis.map(kpi=>(
                <div className="single-kpi-block">
                  <div className="kpi-name">{kpi.name}</div>
                  <div className="kpi-number">{kpi.value}</div>
                </div>
              ))}
            </div>
            </div>*/}
						</table>
					);
				})}
			</div>
			<br />
			<div className="highlights-update">
				<p>Data updated on Ott/30/2023</p>
			</div>
		</div>
	);
}

export default Highlights;
