import React from "react";

type SocielMediaProps = {
	href: string;
	altMin: string;
	alt: string;
    text: string;
};

function SocialMedia(props: SocielMediaProps): React.JSX.Element {
	return (
		<div className="social-media-div">
			<a href={props.href} target="_blank" rel="noreferrer">
				<div>
					<img
						className="social-media-img"
						src={`/assets/social-media-icons/${props.altMin}-logo.png`}
						alt={`${props.alt}-logo`}
					/>
				</div>
				<div className="social-name">{props.text}</div>
			</a>
		</div>
	);
}

type SocialData = {
	text: string;
	href: string;
	alt: string;
	altMin: string;
};

function Media(): React.JSX.Element {
	const socialData: SocialData[] = [
		{
			text: "Leonardo Pinna - YouTube",
			href: "https://youtube.com/c/LeonardoPinna",
			alt: "youtube",
			altMin: "yt",
		},
		{
			text: "Leonardo Pinna - Instagram",
			href: "https://instagram.com/leonardopinna_official",
			alt: "instagram",
			altMin: "ig",
		},
		{
			text: "Leonardo Pinna - Twitch",
			href: "https://twitch.tv/leopinna_",
			alt: "twitch",
			altMin: "tw",
		},
		{
			text: "Leonardo Pinna's Website",
			href: "https://www.leonardopinna.com",
			alt: "leonardo-pinna",
			altMin: "lp",
		},
		{
			text: "Leonardo Pinna - Academy",
			href: "https://academy.leonardopinna.com",
			alt: "academy-leonardo-pinna",
			altMin: "ac",
		},
		{
			text: "Leonardo Pinna - LinkedIn",
			href: "https://www.linkedin.com/in/leonardopinna",
			alt: "linkedin",
			altMin: "ln",
		},
	];
	return (
		<div className="social-container">
			{socialData.map((data) => (
				<SocialMedia
					text={data.text}
					href={data.href}
					altMin={data.altMin}
					alt={data.alt}
				/>
			))}
		</div>
	);
}

export default Media;
