import React from "react";
import { SubBlock } from "./Components";
function Writings(): React.JSX.Element {
	const writingData = [
		{
			href: "/assets/blog/easy-plan-to-get-rich-with-stocks.pdf",
			date: "2023-10-30",
			text: "Easy Plan to Get Rich with Stocks",
		},
		{
			href: "/assets/blog/stupid-plan-to-get-rich-with-real-estate.pdf",
			date: "2023-10-18",
			text: "Easy Plan to Get Rich with Real Estate",
		},
	];

	return (
		<div className="inner-div">
			<h2>Writings</h2>
			{writingData.map((data) => (
				<SubBlock href={data.href} text={data.text} />
			))}
		</div>
	);
}

export default Writings;
