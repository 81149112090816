import Position from "../models/Position";
import TickerCurrency from "../models/TickerCurrency";

import dividends from "./dividends";
import transactions from "./transactions";

function filterUniqueFirstElements(arr: TickerCurrency[]): TickerCurrency[] {
	const uniqueFirstElements = new Set();
	return arr.filter((subarray) => {
		const firstElement = subarray.ticker;
		if (!uniqueFirstElements.has(firstElement)) {
			uniqueFirstElements.add(firstElement);
			return true;
		}
		return false;
	});
}

// La funzione ritorna il portafoglio titoli a partire da un array di transazioni
function createPortfolio(): Position[] {
	let tkrs: TickerCurrency[] = [];
	let pf: Position[] = [];
	transactions.forEach((t) => {
		tkrs.push({ ticker: t.ticker, currency: t.currency });
	});
	tkrs = filterUniqueFirstElements(tkrs);
	tkrs.forEach(function (tkr, i) {
		pf[i] = new Position(tkr.ticker, tkr.currency, transactions, dividends);
	});
	return pf;
}

const portfolio: Position[] = createPortfolio();
portfolio.sort((a, b) => a.ticker.localeCompare(b.ticker));

export default portfolio;
