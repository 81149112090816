import React from "react";

type BlockProps = {
	href: string;
	text: string;
};
function HomeBlock(props: BlockProps): React.JSX.Element {
	return (
		<a className="home-block" href={props.href}>
			{props.text}
		</a>
	);
}

function SubBlock(props: BlockProps): React.JSX.Element {
	return (
		<div className="letter-box">
			<a href={props.href} className="letter-title">
				{props.text}
			</a>
		</div>
	);
}

export { HomeBlock };
export { SubBlock };
