import Transaction from "../models/Transaction";

const transactions = [
	new Transaction(new Date("2022-11-22"), 75.5481, "$", 26, "ATVI", "Buy", 0.9708, 8.74),
	new Transaction(new Date("2022-11-02"), 89.9, "$", 25, "GOOGL", "Buy", 0.9829, 9.16),
	new Transaction(new Date("2022-10-31"), 101.6, "$", 30, "META", "Buy", 0.9972, 9.03),
	new Transaction(new Date("2022-10-03"), 139.03, "$", 15, "META", "Buy", 0.9827, 9.16),
	new Transaction(new Date("2022-09-28"), 101.93, "$", 20, "GOOGL", "Buy", 1.0013, 8.99),
	new Transaction(new Date("2022-09-16"), 98.529, "$", 20, "GOOGL", "Buy", 0.9617, 9.36),
	new Transaction(new Date("2022-06-27"), 115.25, "$", 60, "GOOGL", "Buy", 1.0568, 8.51),
	new Transaction(new Date("2022-06-28"), 170.0, "$", 20, "META", "Buy", 1.0521, 8.55),
	new Transaction(new Date("2023-02-03"), 193.35, "$", 25, "META", "Sell", 1, 8.25),
	new Transaction(new Date("2023-10-18"), 24.97, "£", 180, "BATS", "Buy", 1, 25.47),
	new Transaction(new Date("2023-04-27"), 28.0, "$", 50, "LZB", "Buy", 1, 1.0),
	new Transaction(new Date("2023-04-04"), 7.5, "$", 200, "MBC", "Buy", 1, 1.0),
	new Transaction(new Date("2023-10-13"), 95, "$", 26, "ATVI", "Sell", 1, 0),
	new Transaction(new Date("2023-05-08"), 384, "$", 1, "GOOGL18AUG23115C", "Sell", 1, 1.05),
	new Transaction(new Date("2023-08-18"), 1208, "$", 1, "GOOGL18AUG23115C", "Buy", 1, 1.05),
	new Transaction(new Date("2023-05-08"), 963, "$", 1, "META18AUG23260C", "Sell", 1, 1.06),
	new Transaction(new Date("2023-08-18"), 1828, "$", 1, "META18AUG23260C", "Buy", 1, 1.05),
	new Transaction(new Date("2024-01-29"), 401.41, "$", 40, "META", "Sell", 1, 1.14),
	new Transaction(new Date("2024-01-29"), 153.52, "$", 125, "GOOGL", "Sell", 1, 2.17),
	new Transaction(new Date("2024-01-29"), 14.585, "$", 200, "MBC", "Sell", 1, 1.06),
	new Transaction(new Date("2024-02-27"), 137.765, "$", 125, "GOOGL", "Buy", 1, 1.0),
	new Transaction(new Date("2024-02-27"), 180.365, "$", 100, "AAPL", "Buy", 1, 1.0),
	new Transaction(new Date("2024-03-05"), 170.37, "$", 40, "AAPL", "Buy", 1, 1.0),
];

transactions.sort((a, b) => b.date.getTime() - a.date.getTime());

export default transactions;