import React from "react";

function History(): React.JSX.Element {
	const historyData = [
		{
			year: 2022,
			text: `
            During 2022, the first year of corporate activities, Money Goals SRL
            continued to grow the online audience on YouTube and Instagram: at
            the end of the year, Leonardo's YouTube channel counted over 82000
            susbscribers and Leonardo's Instagram Page counted over 4000
            followers.\n
            In september 2022 the company launched the Leonardo Pinna's Academy (1)
            to teach people about finance and money. On the same month, the
            company launched the first course in the academy "Stock investing
            for beginners" in italian language.\n
            In october, the company launched Leonardo Pinna's TikTok account (2). 
            By the end of the year, the account counted over 2000 new
            followers.
        `,
			links: [
				{
					href: "https://academy.leonardopinna.com",
					name: "(1) Academy",
				},
				{
					href: "https://www.tiktok.com/@leonardopinna_official",
					name: "(2) TikTok",
				},
			],
		},
		{
			year: 2021,
			text: `
          Money Goals SRL was founded in december 2021 by its current sole
          owner and director Leonardo Pinna. Money Goals SRL is the
          continuation of the online business brand and activity of Leonardo
          Pinna. Beginning operations, Leonardo Pinna's YouTube channel (1)
          built in early 2019, now owned by the company, counted over 65000
          subscribers, and Leonardo Pinna's Instagram page (2),
          also owned by Money Goals SRL, counted over 2500 followers.
        `,
			links: [
				{
					href: "https://youtube.com/c/Leonardopinna",
					name: "(1) YouTube",
				},
				{
					href: "https://instagram.com/leonardopinna_official",
					name: "(2) Instagram",
				},
			],
		},
	];

	return (
		<div className="inner-div">
			<h2 className="page-title">Company History</h2>
			<div>
				{historyData.map((yearData) => (
					<div className="history-block">
						<h4>{yearData.year}</h4>
						<div className="history-text">{yearData.text}</div>
						<div className="history-links">
							{yearData.links.map((link) => (
								<a href={link.href}>{link.name}</a>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default History;
