import type Dividend from "./Dividend";
import type Transaction from "./Transaction";

export default class Position {
	ticker: string;
	currency: string;
	transList: Transaction[];
	divList: Dividend[];

	constructor(ticker: string, currency: string, transList: Transaction[], divList: Dividend[]) {
		this.transList = transList;
		this.divList = divList;
		this.ticker = ticker;
		this.currency = currency;
	}

	calcAvgBuyPrice(): number {
		let totalCost = 0;
		let totalShares = 0;
		this.transList.forEach((t) => {
			if (t.ticker === this.ticker && t.type === "Buy") {
				totalCost = totalCost + t.calcAmount();
				totalShares = totalShares + t.quantity;
			}
		});
		if (totalShares) {
			return totalCost / totalShares;
		} else {
			return 0;
		}
	}

	calcTotalShares(): number {
		let totalShares = 0;
		this.transList.forEach((t) => {
			if (t.ticker === this.ticker) {
				if (t.type === "Buy") {
					totalShares = totalShares + t.quantity;
				} else if (t.type === "Sell") {
					totalShares = totalShares - t.quantity;
				}
			}
		});
		return totalShares;
	}

	calcTotalPurchaseCost(): number {
		let total = 0;
		this.transList.forEach((t) => {
			if (t.ticker === this.ticker && t.type === "Buy") {
				total += t.calcAmount();
			}
		});
		return total;
	}

	calcTotalSellingRevenue(): number {
		let total = 0;
		this.transList.forEach((t) => {
			if (t.ticker === this.ticker && t.type === "Sell") {
				total += t.calcAmount();
			}
		});
		return total;
	}

	calcTotalCommission(): number {
		let total = 0;
		this.transList.forEach((t) => {
			if (t.ticker === this.ticker) {
				total += t.commission;
			}
		});
		return total;
	}

	calcNetDividends(): number {
		let total = 0;
		this.divList.forEach((d) => {
			if (d.ticker === this.ticker) {
				total += d.getNetAmount();
			}
		});
		return total;
	}
}
