class Transaction {
	date: Date;
	price: number;
	currency: string;
	quantity: number;
	ticker: string;
	type: string;
	fxRate: number;
	commission: number;

	// put 1.0 forex rate if commission are calculated in stock base currency
	constructor(
		date: Date,
		price: number,
		currency: string,
		quantity: number,
		ticker: string,
		type: string,
		fxRate: number,
		commission: number
	) {
		this.date = date;
		this.price = price;
		this.currency = currency;
		this.quantity = quantity;
		this.ticker = ticker;
		this.type = type;
		this.fxRate = fxRate;
		this.commission = commission;
	}

	calcAmount(): number {
		return this.price * this.quantity;
	}

	calcCurrencyCost(): number {
		if (this.type === "Buy") {
			return this.price * this.quantity + this.commission * this.fxRate;
		} else if (this.type === "Sell") {
			return -this.price * this.quantity + this.commission * this.fxRate;
		}
		return 0;
	}
	calcEurCost(): number {
		return this.price * this.fxRate * this.quantity;
	}

	calcTotEurCost(): number {
		if (this.type === "Buy") {
			return (this.price / this.fxRate) * this.quantity + this.commission;
		} else if (this.type === "Sell") {
			return -(this.price / this.fxRate) * this.quantity + this.commission;
		}
		return 0;
	}

	getDateString(): string {
		return this.date.toISOString().split("T")[0];
	}
}

export default Transaction;
