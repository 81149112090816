import React from "react";

function Header(): React.JSX.Element  {
	return (
		<>
			<header className="money-goals-header">
				<h1>
					<a href="/" className="header-home">Money Goals SRL</a>
				</h1>
			</header>
		</>
	);
}

export default Header;
