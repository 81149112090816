import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import Disclaimer from "./Disclaimer";
import Financials from "./Financials";
import Highlights from "./Highlights";
import Holdings from "./Holdings";
import Info from "./Info";
import Letters from "./Letters";
import Media from "./Media";
import MoneyManagerApp from "./MoneyManagerApp";
import News from "./News";
import OtherProjects from "./OtherProjects";
import Writings from "./Writings";
import History from "./History";

function App(): React.JSX.Element {
	return (
		<div id="body-container">
			<BrowserRouter>
				<Routes>
					<Route path="/">
						<Route
							index
							element={
								<WithLayout>
									<Home />
								</WithLayout>
							}
						/>
						<Route
							path="disclaimer"
							element={
								<WithLayout>
									<Disclaimer />
								</WithLayout>
							}
						/>
						<Route
							path="financials"
							element={
								<WithLayout>
									<Financials />
								</WithLayout>
							}
						/>
						<Route
							path="highlights"
							element={
								<WithLayout>
									<Highlights />
								</WithLayout>
							}
						/>
						<Route
							path="history"
							element={
								<WithLayout>
									<History />
								</WithLayout>
							}
						/>
						<Route
							path="holdings"
							element={
								<WithLayout>
									<Holdings />
								</WithLayout>
							}
						/>
						<Route
							path="info"
							element={
								<WithLayout>
									<Info />
								</WithLayout>
							}
						/>
						<Route
							path="letters"
							element={
								<WithLayout>
									<Letters />
								</WithLayout>
							}
						/>
						<Route
							path="media"
							element={
								<WithLayout>
									<Media />
								</WithLayout>
							}
						/>
						<Route
							path="money-manager-app"
							element={
								<WithLayout>
									<MoneyManagerApp />
								</WithLayout>
							}
						/>
						<Route
							path="news"
							element={
								<WithLayout>
									<News />
								</WithLayout>
							}
						/>
						<Route
							path="other-projects"
							element={
								<WithLayout>
									<OtherProjects />
								</WithLayout>
							}
						/>
						<Route
							path="writings"
							element={
								<WithLayout>
									<Writings />
								</WithLayout>
							}
						/>
						{/*<Route path="/delete-user" element={<DeleteUser />} /> */}
						<Route path="*" element={<Navigate to="/" replace />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

interface WithLayoutProps<T extends React.ReactNode> {
	children: T;
}

function WithLayout<T extends React.ReactNode>({
	children,
}: WithLayoutProps<T>): React.JSX.Element {
	return (
		<>
			<Header />
			<hr />
			<div className="global-div">{children}</div>
			<hr />
			<Footer />
		</>
	);
}

export default App;
