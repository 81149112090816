import React from "react";

function Footer(): React.JSX.Element {
	const currentYear = new Date().getFullYear();

	return (
		<footer>
			<div>
				Copyright © 2022 - {currentYear} | All Rights Reserved | Money Goals SRL
				Unipersonale | P.IVA IT04001560368 | Cap. Soc.: 10.000€ I.V.
			</div>
		</footer>
	);
}

export default Footer;
