import React from "react";
import { HomeBlock } from "./Components";

function Home(): React.JSX.Element {
	return (
		<div className="home-table">
			<HomeBlock href="/info" text="Company Information" />
			<HomeBlock href="/history" text="Company History" />
			<HomeBlock href="/news" text="News" />
			<HomeBlock href="/financials" text="Financials" />
			<HomeBlock href="/letters" text="Letters" />
			<HomeBlock href="/writings" text="Writings" />
			<HomeBlock href="/assets/documents/Our-7-Principles.pdf" text="Our Principles" />
			<HomeBlock href="/holdings" text="Holdings" />
			<HomeBlock href="/media" text="Social Media" />
			<HomeBlock href="/highlights" text="Highlights" />
			<HomeBlock href="/other-projects" text="Other Projects" />
			<HomeBlock href="/disclaimer" text="Legal Disclaimer" />
		</div>
	);
}

export default Home;
