import React from "react";

function Disclaimer(): React.JSX.Element {

  const disclaimerText = `
      This website has been prepared solely for the purpose of providing
      information about Money Goals SRL and the services and products it
      offers. This website has been compiled in good faith by Money Goals SRL.
      However, no representation is made as to the completeness or accuracy of
      the information it contains. In particular, you should be aware that
      this information may be incomplete, may contain errors or may have
      become out of date. The reports filed by Money Goals SRL with the
      Italian "Registro Imprese" and listed on this website speak only as of
      the respective dates on which they are filed or used by Money Goals SRL.
      The contents of those reports can become out-of-date. Money Goals SRL
      makes no commitment, and disclaims any duty, to update any of those
      reports. Money Goals SRL reserves the right to add, modify or delete any
      information at this website at any time. This publication and any
      references to products or services are provided "as is" without any
      warranty or implied term of any kind. Reproduction or distribution of
      any materials obtained on this website or linking to this website
      without written permission is prohibited.
  `
	return (
    <div className="disclaimer-text">
      {disclaimerText}
    </div>
	);
}

export default Disclaimer;
