import React from "react";

function MoneyManagerApp(): React.JSX.Element {
	return (
		<div className="inner-div">
			<h2>Money Manager App</h2>
			<div>
				{/*<image href=""></image>*/}
			</div>
			<div className="home-data">
				<div className="app-section">
					<h3 className="news-title">
						Downloads
					</h3>
					<div className="app-link-container">
						<div className="app-link">
							<a href="./downloads/money-manager-0.5.dmg">
								<strong>
									DOWNLOAD FOR MAC (Version 0.5 - ALPHA)
								</strong>
							</a>
						</div>

						<div className="app-link">
							<a href="./downloads/money-manager-0.5.zip">
								<strong>
									DOWNLOAD FOR WINDOWS (Version 0.5 - ALPHA)
								</strong>
							</a>
						</div>
					</div>
				</div>

				<div className="app-section">
					<h3 className="news-title">
						Description
					</h3>
					<p className="app-description">
						Money Manager App is an application developed to contain
						financial accounts and transactions and, ultimately, show your financial status.
						<br />
						Note: the app is in development stage and the version 1.0 is not complete.
					</p>
					<h4 className="news-title" style={{color: "red"}}>
						Important note
					</h4>
					<p className="app-description">
						When downloading the app, you could get a warning related to the untrusted nature
						of the current application. If you trust the authors (us), you can choose to
						open the application despite the warnings.
					</p>
				</div>

				<div className="app-section">
					<h3 className="news-title">
						Report a bug
					</h3>
					<div className="app-link-container">
						<div className="app-link">
							<a href="https://github.com/Money-Goals-SRL/www.moneygoals.srl/issues/new" target="_blank" rel="noreferrer">
								<strong>
									REPORT A BUG ON GITHUB
								</strong>
							</a>
						</div>
					</div>
					<p>
						If you want to report a bug, you can do it by opening an issue on GitHub using the link above.
					</p>
				</div>

				<div className="app-section">
					<h3 className="news-title">
						License
					</h3>
					<p>
						This software is provided for free use and testing only and may not be reproduced, modified,
						reverse-engineered, or distributed without
						explicit permission from the owner (Money Goals SRL).
					</p>
				</div>

				<div className="app-section">
					<h3 className="news-title">
						Disclaimer
					</h3>
					<p>
						This software is provided for <strong>testing purposes</strong>. Money Goals SRL, its personnel
						and
						collaborators are not responsible for any data loss related to file produced or read
						by the application. The application is in development stage, and thus there could be
						many diverse unexpected problems in using the app. If you find a bug, you can open an issue on
						GitHub.
					</p>
				</div>

				<div className="app-section">
					<h3 className="news-title">
						Contributions
					</h3>
					<p>
						We currently do not accept third party contributions. If you want to support the project, you
						can
						donate through <a
							href={"https://www.paypal.com/donate/?hosted_button_id=XM3KTA474KFL2"}>Paypal</a>.
					</p>
				</div>

			</div>
		</div>
	);
}

export default MoneyManagerApp;
