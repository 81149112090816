import React from "react";
import { SubBlock } from "./Components";

function Financials(): React.JSX.Element {
	const financialData = [
		{
			href: "/assets/financial-statements/2022.pdf",
			text: "2022",
		},
	];
	const noteText = `
          Note: To provide a more lean document, some pages have been removed.
          Missing pages do not contain relevant or critical information. You
          should however rely on official documents deposited in the Italian
          "Registro Imprese" database.
          `;

	return (
		<div className="inner-div">
			<h2>Financial Statements</h2>
			{financialData.map((data) => (
				<SubBlock href={data.href} text={data.text} />
			))}
			<div className="financial-text">{noteText}</div>
		</div>
	);
}

export default Financials;
