class Dividend {
	date: Date;
	dps: number;
	currency: string;
	quantity: number;
	ticker: string;
	whTaxRate: number;
	commission: number;

	// put 1.0 forex rate if commission are calculated in stock base currency
	constructor(
		date: Date,
		dps: number,
		currency: string,
		quantity: number,
		ticker: string,
		withholdingTaxRate: number,
		commission: number
	) {
		this.date = new Date(date);
		this.dps = dps;
		this.currency = currency;
		this.quantity = quantity;
		this.ticker = ticker;
		this.whTaxRate = withholdingTaxRate;
		this.commission = commission;
	}

	getTotalWithHoldingTax(): number {
		return this.whTaxRate * this.dps * this.quantity;
	}

	getTotalGrossAmount(): number {
		return this.dps * this.quantity;
	}

	getNetAmount(): number {
		return this.dps * this.quantity * (1 - this.whTaxRate) - this.commission;
	}

	getDateString(): string {
		return this.date.toISOString().split("T")[0];
	}
}

export default Dividend;
