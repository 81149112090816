import React from "react";
import { SubBlock } from "./Components";

function Letters(): React.JSX.Element {
	const letterData = [
		{
			href: "/assets/Letters/2022-Letter.pdf",
			text: "2022",
		},
	];

	return (
		<div className="inner-div">
			<h2>Letters to Shareholders</h2>
			{letterData.map((data) => (
				<SubBlock href={data.href} text={data.text} />
			))}
		</div>
	);
}

export default Letters;
