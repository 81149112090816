import React from "react";

function News(): React.JSX.Element {

  const newsData = [
    {
      date: "30/11/2023", 
      title: "Money Goals SRL and Simone Gramellini suspend collaboration", 
      text: `
        Following corporate restucturing for 2024, Money Goals SRL decided to suspend the collaboration with 
        Mr. Gramellini, who brilliantly produced our video contents. We wish Mr. Gramellini good luck and a bright career, and
        hopefully a restart of our collaboration in the future.
      `, 
      docList: [
        {
          href: "https://www.instagram.com/gramesnaps/",
          name: "Simone Gramellini Instagram Page"
        }
      ]
    }, 
    {
      date: "15/10/2023", 
      title: "Microsoft completes ATVI purchase: profit for Money Goals SRL", 
      text: `Last week, Microsoft completed the acquisition of Activision Blizzard (ATVI) by purchasing
      its stocks for 95.00$ per share. Thus, as an ex-shareholder of ATVI, Money Goals SRL profited from
      the previously purchased ATVI stocks in early 2023. More information in the Holdings section.`, 
      docList: [
        {
          href: "/holdings",
          name: "Holdings page"
        }
      ]
    }, 
    {
      date: "30/09/2023", 
      title: "Money Goals SRL published its financial statements for the year 2022", 
      text: `
      Money Goals SRL release today the financial statement for the company activities in 2022. You can find all
      previousfinancial statements in the Financial section.
      `, 
      docList: [
        {
          href: "/assets/financial-statements/2022.pdf", 
          name: "Link to Financial Statements 2022"
        }
      ]
    }, 
    {
      date: "12/02/2023", 
      title: "Money Goals SRL annuncia una vendita di asset non produttivi (IT)", 
      text: `
      Money Goals SRL annuncia che inizia da oggi una vendita di asset
      non produttivi della società. La vendita riguarderà in particolare
      hardware informatici. Maggiori informazioni all'interno del
      documento allegato.
      `, 
      docList: [
        {
          href: "/assets/news/20230216_vendita_01_2023.pdf", 
          name: "On-Sale Products"
        }
      ]
    }
  ]
  
	return (
    <div className="inner-div">
      <h2>Company Recent News</h2>
      <hr />
      <div className="news-outer-container">
        {newsData.map(data=>(
          <div className="news-box">
            <div className="news-div">
              <h4 className="news-title">
                {data.date} - {data.title}
              </h4>
              <p className="news-text">{data.text}</p>
            </div>
            <hr className="vertical-hr" />
            {data.docList.map(doc=>(
              <div className="news-doc-div">
                <h4 className="news-title">Documents</h4>
                <p className="news-attachment">
                  <a href={doc.href} target="_blank" rel="noreferrer">{doc.name}</a>
                </p>
              </div>
            ))}
            </div>
        ))}
      </div>
      </div>
	);
}

export default News;
